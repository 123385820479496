'use client'

import './Footer.css'

import { Icon } from "@iconify/react";
import Link from 'next/link';
import { useRouter } from "next/navigation";

const Footer = () => {
    const router = useRouter();

    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-white py-4">
            <div className="flex flex-col items-center">
                <img src="/LOGO.svg" alt="Logo" className="mb-4" />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-1 md:gap-8 text-center text-sm md:text-base">
                    <div className="flex flex-col mb-1 md:mb-8">
                        <h2 className="font-semibold text-lg md:mb-4">Vrijeme</h2>
                        <ul>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/hrvatska/zagreb">Hrvatska</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/bioprognoza">Bioprognoza</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/nautika">Nautika</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/vijesti">Vijesti</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col mb-1 md:mb-8">
                        <h2 className="font-semibold text-lg md:mb-4">Stanje na cestama</h2>
                        <ul>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/stanje-u-prometu">Stanje u prometu</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/granicni-prijelazi">Granični prijelazi</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col mb-1 md:mb-8">
                        <h2 className="font-semibold text-lg md:mb-4">Kamere</h2>
                        <ul>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/kamere">Kamere po gradovima</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col mb-1 md:mb-8">
                        <h2 className="font-semibold text-lg md:mb-4">O nama</h2>
                        <ul>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/terms-of-use">Uvjeti korištenja</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/politika-privatnosti">Politika privatnosti</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/pojmovnik">Pojmovnik</Link>
                            </li>
                            <li className='pb-1 md:pb-2'>
                                <Link className='hover:text-[#0287c2d9] transition duration-200' href="/widget">Widget</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="font-semibold text-lg md:mb-4">Kontakt</h2>
                        <Link className='pb-1 md:pb-2 hover:text-[#0287c2d9] transition duration-200' href="/kontaktiraj-nas">Kontaktiraj nas</Link>
                        <div className="flex flex-row items-center justify-center mb-2">
                            <a href="https://www.facebook.com/vrijeme.net" target="_blank" rel="noopener noreferrer">
                                <Icon
                                    className="mx-2 text-3xl"
                                    style={{ color: "rgba(2, 135, 194, 1)", transition: 'color 0.2s' }}
                                    icon="ic:baseline-facebook"
                                    onMouseOver={(e) => (e.currentTarget.style.color = '#fcba03')}
                                    onMouseOut={(e) => (e.currentTarget.style.color = 'rgba(2, 135, 194, 1)')} />
                            </a>
                            <a href="mailto:info@vrijeme.net">
                                <Icon
                                    className="mx-2 text-3xl"
                                    style={{ color: "rgba(2, 135, 194, 1)", transition: 'color 0.2s' }}
                                    icon="ic:baseline-email"
                                    onMouseOver={(e) => (e.currentTarget.style.color = '#fcba03')}
                                    onMouseOut={(e) => (e.currentTarget.style.color = 'rgba(2, 135, 194, 1)')} />
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="w-11/12 mx-auto my-6" />
                <p className="text-center">Copyright: {currentYear} Vrijeme.net</p>
            </div>
        </footer>
    );
};

export default Footer;
