import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';


const MobileModal = ({ isOpen, onClose, onDropdownClick, dropdownOpen }) => {
  const router = useRouter();

  const routeAndClose = (destination) => {
    router.push(destination);
    onClose();
  };

  return (
    <div className={`fixed top-0 left-0 w-full h-full z-[9999] ${isOpen ? '' : 'hidden'}`}>
      <div className="bg-blue-500 flex justify-between items-center" style={{ backgroundColor: '#0287C2', height: '61px' }}>
        <div className="flex items-center mx-2.5 w-full">
          <button type="button" onClick={() => routeAndClose("/")} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <img src="/LOGOwhite.svg" alt="Logo" />
          </button>
          <div className='ml-auto'>
            <Icon onClick={onClose} icon="material-symbols:close" className="text-white text-2xl" />
          </div>
        </div>
      </div>
      <div className="bg-black bg-opacity-50 h-full">
        <div className="bg-white p-6 h-screen">
          <ul className="space-y-4">
            <li><button type="button" onClick={() => routeAndClose("/vrijeme/hrvatska/zagreb")}>Vrijeme</button></li>
            <li><button type="button" onClick={() => routeAndClose("/bioprognoza")}>Bioprognoza</button></li>
            <li><button type="button" onClick={() => routeAndClose("/nautika")}>Nautika</button></li>
            <li
              className="relative dropdown-trigger"
              onClick={onDropdownClick}
              style={{ zIndex: dropdownOpen ? '10' : '1' }}
            >
              <span className="flex items-center">
                <span style={{ paddingRight: '8px' }}>Stanje na cestama</span>
                <img
                  src="/polygon-black.svg"
                  alt="Dropdown Indicator"
                  style={{
                    transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                    width: '13px',
                    height: '8px',
                    fill: 'black'
                  }}
                />
              </span>
              {dropdownOpen && (
                <ul className="text-black bg-white pt-2 px-3 mt-2 space-y-4 dropdown-menu">
                  <li><button type="button" onClick={() => routeAndClose("/stanje-u-prometu")}>Stanje u prometu</button></li>
                  <li><button type="button" onClick={() => routeAndClose("/granicni-prijelazi")}>Granični prijelazi</button></li>
                </ul>
              )}
            </li>
            <li><button type="button" onClick={() => routeAndClose("/kamere")}>Kamere</button></li>
            <li><button type="button" onClick={() => routeAndClose("/vijesti")}>Vijesti</button></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileModal;
