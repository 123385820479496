'use client'

import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@iconify/react';
import MobileModal from '../MobileModal/MobileModal';
import { useRouter } from 'next/navigation';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

const Header = () => {
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const pathname = usePathname();
  const [currentPage, setCurrentPage] = useState('null');

  const dropdownRef = useRef(null);

  const boldCurrentPage = () => {
    const trimmedPathname = pathname.replace(/^\//, '');
    const pageWithoutQuery = trimmedPathname.split('?')[0];
    setCurrentPage(pageWithoutQuery);
  };

  useEffect(() => {
    boldCurrentPage();
  }, [pathname]);

  const handleClickOutside = (event) => {
    if (
      !event.target.closest('.dropdown-menu') &&
      !event.target.closest('.dropdown-trigger')
    ) {
      setDropdownOpen(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-blue-500 flex justify-between items-center xl:px-44 z-[10000]" style={{ backgroundColor: '#0287C2', height: '61px' }}>
      <div className="flex items-center ml-2.5">
        <button type="button" onClick={() => router.push("/")} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <img src="/LOGOwhite.svg" alt="Logo" />
        </button>
      </div>
      <nav style={{ paddingRight: '10px' }}>
        <ul className="flex space-x-10">
          <li>
            <Link href="/hrvatska/zagreb" className={`text-white hidden xl:inline-block ${currentPage === '[country]/[city]' ? 'font-semibold' : 'transition duration-200 hover:opacity-80'}`}>Vrijeme</Link>
          </li>

          <li>
            <Link href="/bioprognoza" className={`text-white hidden xl:inline-block ${currentPage === 'bioprognoza' ? 'font-semibold' : 'transition duration-200 hover:opacity-80'}`}>Bioprognoza</Link>
          </li>

          <li>
            <Link href="/nautika" className={`text-white hidden xl:inline-block ${currentPage === 'nautika' ? 'font-semibold' : 'transition duration-200 hover:opacity-80'}`}>Nautika</Link>
          </li>

          <li
            className="text-white relative hidden xl:inline-block dropdown-trigger"
            onClick={toggleDropdown}
            style={{ zIndex: dropdownOpen ? '10000' : '1' }}
          >
            <span className="flex items-center">
              <span className={`cursor-pointer ${(currentPage === 'stanje-u-prometu' || currentPage === 'granicni-prijelazi') ? 'font-semibold' : ''}`} style={{ paddingRight: '8px' }}>Stanje na cestama</span>
              <img
                src="/polygon.svg"
                alt="Dropdown Indicator"
                style={{
                  transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                  width: '13px',
                  height: '8px',
                }}
              />
            </span>
            {dropdownOpen && (
              <ul className="text-black absolute bg-white py-2 px-3 mt-2 space-y-2 dropdown-menu">
                <li>
                  <Link href="/stanje-u-prometu" className={`text-black hidden xl:inline-block ${currentPage === 'stanje-u-prometu' ? 'font-semibold' : 'transition duration-200 hover:text-[#0287c2d9]'}`}>Stanje u prometu</Link>
                </li>

                <li>
                  <Link href="/granicni-prijelazi" className={`text-black hidden xl:inline-block ${currentPage === 'granicni-prijelazi' ? 'font-semibold' : 'transition duration-200 hover:text-[#0287c2d9]'}`}>Granicni prijelazi</Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link href="/kamere" className={`text-white hidden xl:inline-block ${currentPage === 'kamere' ? 'font-semibold' : 'transition duration-200 hover:opacity-80'}`}>Kamere</Link>
          </li>

          <li>
            <Link href="/vijesti" className={`text-white hidden xl:inline-block ${currentPage === 'vijesti' ? 'font-semibold' : 'transition duration-200 hover:opacity-80'}`}>Vijesti</Link>
          </li>

          <li className="xl:hidden">
            <Icon
              icon="material-symbols:menu"
              className="text-white cursor-pointer"
              style={{ fontSize: '24px' }}
              onClick={openModal}
            />
          </li>
        </ul>
        {modalOpen && (
          <MobileModal
            isOpen={modalOpen}
            onClose={closeModal}
            onDropdownClick={toggleDropdown}
            dropdownOpen={dropdownOpen}
            currentPage={currentPage}
          />
        )}
      </nav>
    </header>
  );
};

export default Header;
